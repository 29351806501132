.authFormWrap {
    max-width: 500;
    width: 65%;
}

@media(max-width: 500px) {
    .authFormWrap {
        max-width: 500;
        width: 90%;
    }
}

input[type="radio"]:checked + span {
    display: block;
  }
