@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

/*=== Default  ===*/
:root {
  --app-color-primary: #01065a;
  --app-color-secondary: #65de02;
  /* --app-color-accent: 240 4.8% 95.9%; */
  --app-color-accent: #344054;
  --primary: 240 5.9% 10%;
  --app-color-neutral: #fcfcfd;
}

html {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}
.ant-steps-item-tail {
  display: none !important;
}
body {
  font-family: "Inter", sans-serif;
  background: var(--app-color-neutral);
}

h1 {
  color: #101828;
}
h3 {
  color: #344054;
}
.ant-picker-ok {
  background-color: var(--app-color-primary) !important;
  color: var(--app-color-neutral) !important;
}

:where(.css-dev-only-do-not-override-1kuana8).ant-steps
  .ant-steps-item-process
  .ant-steps-item-icon {
  background-color: #01065a;
  border-color: #01065a;
}

:where(.css-dev-only-do-not-override-1kuana8).ant-steps
  .ant-steps-item-finish
  .ant-steps-item-icon {
  background-color: #dfe1ff;
  border-color: #dfe1ff;
}

:where(.css-dev-only-do-not-override-1kuana8).ant-steps
  .ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon {
  color: #01065a;
}

.Container {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

@media (min-width: 768px) {
  .Container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.button {
  @apply border rounded bg-primary border-separate font-medium text-white transition ease-in-out duration-300 text-sm tracking-wider hover:opacity-70;
  padding: 7px 10px 8.8px 10px;
}

.transparentButton {
  @apply border border-slate-400 hover:border-slate-800 rounded font-medium bg-transparent transition ease-in-out duration-500 text-sm tracking-wider;
  padding: 6.5px 10px;
}

/* .ant-switch {
  background-color: rgba(0, 0, 0, 0.25) !important;
} */

/* .ant-switch-checked {
  background-color: var(--app-color-primary) !important;
} */

/* tab */
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--app-color-primary);
}

.ant-tabs-ink-bar {
  background: var(--app-color-primary);
}

.ant-tabs .ant-tabs-tab:hover {
  color: var(--app-color-secondary);
}

.ant-switch.css-dev-only-do-not-override-k7429z {
  background: #5e5e5e !important;
}

.ant-switch.css-dev-only-do-not-override-k7429z.ant-switch-checked {
  background: var(--app-color-primary) !important;
}

.ant-btn.css-dev-only-do-not-override-k7429z.ant-btn-primary.ant-btn-sm {
  background: var(--app-color-primary) !important;
}

.ant-btn.css-dev-only-do-not-override-1kuana8.ant-btn-primary.ant-btn-sm {
  color: var(--app-color-primary) !important;
}

input[type="checkbox"] {
  accent-color: var(--app-color-primary) !important;
}

.checkbox-group {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.bg-primary\/10 {
  background-color: hsl(var(--primary) / 0.1);
}
