.hover\:bg-accent:hover {
  background-color: hsl(var(--accent));
}

/* h3:hover {
  color: #f9fafb !important;
} */
.bg-primary {
  color: #f9fafb;
}

.data-\[state\=checked\]\:bg-primary[data-state="checked"] {
  background-color: #f9fafb !important;
}